<template>
  <div class="loan-protection-insurance">
    <h1>Help with Childcare Costs</h1>
    <b-img fluid src="/images/free-childcare.png" alt="Claim upto 15 hours free childcare"
           class="d-block mx-auto p-0 float-md-right pl-md-3 col-12 col-sm-8 col-md-5 mb-4" />
    <h3>
      15 Hours Childcare for 2 Year Olds
    </h3>
    <p>
      Applications for 15 hours childcare are now open for eligible working parents in England with a child who turns two on or before 31st March 2024.
    </p>
    <p>
      Parents can apply for their hours on <a href="https://www.gov.uk" target="_blank">www.gov.uk</a> to get their code and then share it with their childcare provider.
    </p>
    <p>
      To continue receiving the government support, you will need to reconfirm your details every 3 months on your <a href="https://www.gov.uk" target="_blank">gov.uk account</a>.
    </p>
    <p>
      From September 2024, 15 hours childcare will be expanded to eligible working parents in England with children aged between 9 months and 23 months.
    </p>
    <p>
      Visit <a href="https://www.childcarechoices.gov.uk/" target="_blank">Childcare Choices</a> to find out eligibility criteria.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Help With Childcare Costs',
  metaInfo() {
    return {
      title: 'Childcare Costs | Help And Support | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "Credit, Union, Family, Loan, help, support, childcare, costs" },
        { name: 'description', content: "Help with childcare csots" }
      ],
    };
  }
}
</script>

<style scoped>

</style>
